<template>
  <div>
    <Header heading="Your Products" class="mb-3">
      <template v-slot:buttons>
        <!-- Import export -->
        <div
          class="d-flex import-exports align-items-center position-relative"
          style="gap: 8px"
        >
          <ImportProduct
            ref="ImportProduct"
            @loadSavedProducts="loadSavedProducts('/loadSavedProducts')"
            @openPlanModal="openUpgradePlansModal"
          ></ImportProduct>
          <ExportProduct
            class="ml-2"
            :exportProductFilter="exportProductFilter"
            :isFileDownload="isFileDownload"
            ref="ExportProduct"
            @export="$emit('export', $event)"
            @loadSavedProducts="loadSavedProducts(`/loadSavedProducts`)"
          ></ExportProduct>
        </div>
        <!-- All Integrations Sync -->

        <div v-if="getUserType != 'web'">
          <Button
            :btnVariant="buttonClassVariant"
            @click="openCategoryModal()"
            @upgradePlan="openUpgradePlan"
            :class="[getUserType == 'wix' ? 'wix-btn bg-dark' : '']"
            class="ml-2"
          >
            <i
              class="fab"
              :class="[
                getUserType == 'shopify'
                  ? 'fa-shopify'
                  : getUserType == 'wix'
                  ? 'fa-wix'
                  : 'fa-bgcommerce',
              ]"
            ></i>
            Import from
            {{
              getUserType == "bc"
                ? "Big Commerce"
                : getUserType == "shopify"
                ? "Shopify"
                : getUserType == "wix"
                ? "Wix"
                : ""
            }}
          </Button>

          <SelectImportProductsCategory
            ref="categoryModal"
            :category="getCategory"
            :IsLimitReached="!IsLimitProductValid"
            @upgradePlan="openUpgradePlan"
          >
            <Button
              :btnVariant="buttonClassVariant"
              @click="openProductModal(getUserType + 'ProductModal')"
              :class="[getUserType == 'wix' ? 'wix-btn bg-dark' : '']"
            >
              Main Products
            </Button>
            <Button
              v-if="getUserType != 'wix'"
              :btnVariant="buttonClassVariant"
              @click="
                openProductVariantModal(getUserType + 'ProductVariantModal')
              "
              :class="[getUserType == 'wix' ? 'wix-btn bg-dark' : '']"
            >
              Variants as Products
            </Button>
          </SelectImportProductsCategory>
          <template v-if="getUserType == 'wix'">
            <WixProductModal
              ref="wixProductModal"
              @updateProducts="loadSavedProducts(`/loadSavedProducts`)"
            >
            </WixProductModal>
            <WixProductVariantModal
              ref="wixProductVariantModal"
              @updateProducts="loadSavedProducts(`/loadSavedProducts`)"
            ></WixProductVariantModal>
          </template>
          <div class="ml-2">
            <div v-if="getUserType == 'shopify' && IsShopifyUser">
              <SyncShopifyProducts
                ref="shopifyProductModal"
                @updateProducts="loadSavedProducts(`/loadSavedProducts`)"
              ></SyncShopifyProducts>
              <SyncShopifyVariantAsProducts
                ref="shopifyProductVariantModal"
                @updateProducts="loadSavedProducts(`/loadSavedProducts`)"
              ></SyncShopifyVariantAsProducts>
            </div>
            <div>
              <div v-if="getUserType == 'bc'">
                <SyncBigCommerceProducts
                  @callDBProducts="loadSavedProducts(`/loadSavedProducts`)"
                  ref="bcProductModal"
                >
                </SyncBigCommerceProducts>
                <SyncBigCommerceVariantAsProducts
                  ref="bcProductVariantModal"
                  @callDBProducts="loadSavedProducts(`/loadSavedProducts`)"
                ></SyncBigCommerceVariantAsProducts>
              </div>
            </div>
            <div
              v-if="getUserType == 'web' || getUserType == 'wordpress'"
            ></div>
          </div>
        </div>
        <WooCommerceProductModal
          @callDBProducts="loadSavedProducts(`/loadSavedProducts`)"
          v-if="isWooCommerceInstalled || isWooCommerceInstalled == 1"
        ></WooCommerceProductModal>
        <Button @click="openCreateNewProduct"> + Add New </Button>
      </template>
    </Header>
  </div>
</template>
<script>
import SelectImportProductsCategory from "./modal/SelectImportProductsCategory.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
// import $ from "jquery";
export default {
  props: {
    loadSavedProducts: Function,
    isFileDownload: Object,
    exportProductFilter: {
      type: Object,
      default: () => ({
        status: "",
        title: "",
        productIds: [],
      }),
    },
  },
  components: {
    SelectImportProductsCategory,
    SyncShopifyVariantAsProducts: () =>
      import(
        /* webpackChunkName: "SyncShopifyProducts" */ "./modal/SyncShopifyVariantAsProducts.vue"
      ),
    SyncShopifyProducts: () =>
      import(
        /* webpackChunkName: "SyncShopifyProducts" */ "./modal/SyncShopifyProducts.vue"
      ),
    WixProductModal: () =>
      import(
        /* webpackChunkName: "WixProductModal" */ "./modal/WixProductModal.vue"
      ),
    WixProductVariantModal: () =>
      import(
        /* webpackChunkName: "WixProductModal" */ "./modal/WixProductVariantModal.vue"
      ),
    Header: () =>
      import(/* webpackChunkName: "Header" */ "../Layout/Header.vue"),
    Button: () =>
      import(/* webpackChunkName: "Button" */ "../Layout/Button.vue"),
    ImportProduct: () =>
      import(
        /* webpackChunkName: "ImportProduct" */ "./modal/ImportProduct.vue"
      ),
    ExportProduct: () =>
      import(
        /* webpackChunkName: "ExportProduct" */ "./modal/ExportProduct.vue"
      ),

    SyncBigCommerceProducts: () =>
      import(
        /* webpackChunkName: "SyncBigCommerceProducts" */ "./modal/SyncBigCommerceProducts.vue"
      ),
    WooCommerceProductModal: () =>
      import(
        /* webpackChunkName: "WooCommerceProductModal" */ "./modal/WooCommerceProductModal.vue"
      ),
    SyncBigCommerceVariantAsProducts: () =>
      import(
        /* webpackChunkName: "SyncBigCommerceVariantAsProducts" */ "./modal/SyncBigCommerceVariantAsProducts.vue"
      ),
  },
  data() {
    return {
      loadingProducts: false,
      upgradeModalTitle: "",
      upgradeModalsubText: "",
      isAllProductLoading: false,
      deleteProductLoading: false,
      productVendorFilter: null,
      TaggedWithFilter: null,
      StatusFilter: null,
      PriceFilter: null,
      searchProducts: "",
      isFirstTimeTableLoad: true,

      pagination: [],
      config: {
        checkbox_rows: true,
        rows_selectable: true,
        pagination: true,
        pagination_info: true,
        num_of_visibile_pagination_buttons: 5,
        per_page: 10,
        per_page_options: [5, 10, 15, 20, 30],
        show_refresh_button: false,
        show_reset_button: false,
        card_mode: true,
        card_title: "",
        highlight_row_hover_color: "#f2f2f2",

        global_search: {
          placeholder: "Enter custom Search text",
          visibility: false,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          searchDebounceRate: 1000,
        },
      },

      isWooCommerceInstalled: false,
    };
  },
  computed: {
    ...mapGetters([
      "GetTokenFromMetaTag",
      "getQuizID",
      "getNavbarData",
      "getUserAllAccountData",
      "getIsShopifyUser",
      "getUserLimitations",
      "getIsAccountDataLoading",
      "getUserAllAccountData",
    ]),
    getCategory() {
      if (this.getUserType)
        return this.getUserType == "bc"
          ? "Big Commerce"
          : this.getUserType?.charAt(0).toUpperCase() +
              this.getUserType?.slice(1);
      return "";
    },
    buttonClassVariant() {
      return this.getUserType == "shopify"
        ? "shopify"
        : this.getUserType == "bc"
        ? "yellow"
        : "";
    },
    IsShopifyUser() {
      return this.getIsShopifyUser;
    },
    getSingleSelectedProduct() {
      if (this.selectedProducts.length) return this.selectedProducts[0];
      return null;
    },
    getUserType() {
      return this.getNavbarData.userType;
    },
    UserAccountData() {
      return this.getNavbarData;
    },

    getSortedProductsArr() {
      if (this.PriceFilter == null) return this.Products;
      if (this.PriceFilter == "reset") return this.Products;

      let products = [...this.Products];
      return products.sort((a, b) => {
        if (this.PriceFilter == "LTH") return a.price - b.price;
        if (this.PriceFilter == "HTL") return b.price - a.price;
      });
    },

    IsLimitProductValid() {
      const allLimitations = [...this.getUserLimitations];

      const limitation = allLimitations.filter(
        (limit) => limit.text == "Products"
      );
      if (limitation.length > 0) {
        return limitation[0].planValue != 0
          ? limitation[0].userValue <= limitation[0].planValue
          : true;
      }

      return false;
    },
  },
  methods: {
    openUpgradePlan() {
      let webUsers = ["web", "bc", "wordpress"];
      if (webUsers.includes(this.getUserType)) {
        let url = window.location.origin + `/manage/account/pricing`;
        window.open(url, "_self");
      }

      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
      if (this.getUserType == "shopify") {
        // const envPath = process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
        // const url = `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`;
        // window.open(url, "_parent");
        const token = localStorage.getItem('user_access_token')
        
        if(token){
          window.open(`https://api.quizell.com/manage/subscription/upgrade/plans?token=${token}`,"_blank")
        }
      }
    },
    ...mapMutations(["setUpgradePlansModal"]),
    openCategoryModal() {
      if (this.getUserType == "wix") {
        this.openProductModal(this.getUserType + "ProductModal");
        return;
      }
      this.$refs.categoryModal.open();
    },
    handleLimitModalPayload() {
      const obj = {
        isOpen: true,
        title: "Upgrade to Import new Products.",
        subText: `You have achieved your products limit, you should upgrade your plan to import new products.`,
      };

      this.setUpgradePlansModal(obj);
    },
    openProductModal(modal) {
      this.$refs.categoryModal.close();

      this.$refs[modal].open();
    },
    openProductVariantModal(modal) {
      this.$refs.categoryModal.close();
      if (!this.IsLimitProductValid) {
        this.handleLimitModalPayload();

        return;
      }
      this.$refs[modal].open();
      this.$refs.categoryModal.close();
    },

    openUpgradePlansModal() {
      this.handleLimitModalPayload();
    },

    openCreateNewProduct() {
      if (this.IsLimitProductValid) {
        this.$router.push({ name: "AddProduct" });
      } else {
        this.openUpgradePlansModal();
      }
    },

    triggerImportProducts() {
      if (!this.IsLimitProductValid) {
        this.handleLimitModalPayload();

        return;
      }

      this.openUpgradePlansModal();
    },

    AddNewProduct() {
      this.$emit("AddNewProductView");
    },
    ProductMatch() {
      this.$emit("ProductMatch");
    },

    async getWooCommerceInstalled(handleKey) {
      try {
        const response = await axios.get(`/getAppInstalledToUser`, {
          params: { handle_key: handleKey },
        });
        if (response.status == 200 && response.data.ok) {
          this.isWooCommerceInstalled = response.data.installed;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
  },
  mounted() {
    if (this.getUserType == "web" || this.getUserType == "wordpress") {
      this.getWooCommerceInstalled("woocommerce");
    }
  },
};
</script>

<style scoped>
.ALLProductDiv {
  padding: 1.5rem 5px;
}

#collection-detail >>> #collection-detail__BV_toggle_ {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

#collection-detail >>> #collection-detail__BV_toggle_::after {
  display: none;
}

#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}

.headTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4b494e;
}

.ProductTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #18191c;
}

.ProductPrice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #18191c;
}

.ProductVendor {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #18191c;
}

.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}

.changeSelection {
  background: #4d1b7e33;
  border-radius: 6px;
  color: #4d1b7e;
}

.duplicateProduct {
  background: rgba(77, 27, 126, 0.2);
  border-radius: 6px;
  color: #4d1b7e;
}

.disableProduct {
  background: rgba(179, 175, 182, 0.2);
  border-radius: 6px;
  color: #4d4950;
}

.enableProduct {
  background: #64bc2633;
  border-radius: 6px;
  color: #64bc26;
}

/* Product Data Table Styles  */
.ProductDataTable .DataTable.card {
  border: none;
}

.ProductDataTable >>> .card-header {
  display: none !important;
}

.ProductDataTable >>> .card-footer {
  display: none;
  background: transparent;
  padding: 0 30px !important;
  border: none;
}

.ProductDataTable >>> thead tr th {
  text-align: start !important;
}

.DataTable >>> tbody tr td {
  vertical-align: middle;
}

.ProductDataTable >>> .vbt-per-page-dropdown .btn-primary {
  background: rgba(77, 27, 126, 0.8) !important;
  border: none !important;
  color: #ffffff !important;
}

.active >>> .page-link {
  background: rgba(77, 27, 126, 0.8) !important;
  text-decoration: none !important;
  color: #ffffff !important;
  z-index: 0;
}

.disabled >>> .page-link {
  background-color: rgb(246 246 247) !important;
  cursor: no-drop !important;
}

.ProductDataTable >>> .input-group .form-control {
  background: #f6f6f7 !important;
}

/* Select filter Group styles */
.FilterDiv {
  width: fit-content;
  /* border: 1px solid red; */
  height: 30px;
  margin-top: 20px;
}

.SelectFormGroup {
  margin: 0 !important;
}

/* .SelectFormGroup .custom-select{
    border:0 !important;
} */

@media only screen and (max-width: 1100px) {
  .ALLProductDiv {
    padding: 1.5rem 5px;
  }

  .card-header {
    padding: 0.75rem 0px;
  }

  .page-link {
    background-color: #4d1b7e !important;
    color: white !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.import-exports::after {
  display: block;
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  background: #e1e3e6;
  right: -4px;
  margin: 4px 0px;
}
</style>
